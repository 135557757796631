/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Mulish:wght@300;400;500;600;700;800;900&display=swap');


// fonts
$base-font-size: 15;
$base-font: 'Mulish', sans-serif;
$heading-font: 'DM Serif Text', serif;



// color
// $dark-gray: #1E2E3E;
$dark-gray: #000;

$body-color: #525252;
$white: #fff;
$light: #8188a9;
$black: #000;
$small-black: #333;
$cyan: #848892;

$theme-primary-color: #CDB091;
$theme-primary-color-s2: #071e93;
$body-bg-color: #fff ;

// background color
// $section-bg-dark: #1E2E3E;
// $section-bg-dark-2: #172533;

$section-bg-dark: #0d0d0d;
$section-bg-dark-2: #000;

$section-bg-color: #fafbfe;
$section-bg-color-s2: #f5f5f5;
$text-color: #6e6e6e;
$text-light-color: #676767;
$heading-color: $dark-gray;
$border-color: #ebebeb;
$border-color-s2: #CEE0F2;
