.desclaimer {
  background-color: black;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 70px;
  align-items: center;
  padding: 140px 140px 0 140px;
  overflow-y: scroll;
}

.desclaimer p {
  font-size: 20px;
  color: white;
  text-align: center;
}

.desclaimerBtnSection {
  display: flex;
  gap: 25px;
}

@media (max-width: 767px) {
  .desclaimer {
    padding: 70px 10px 0 10px;
    gap: 30px;
  }
  .desclaimer p {
    font-size: 16px;
    text-align: left;
  }
}
