/*--------------------------------------------------------------
4. wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer {
  background: $section-bg-dark-2;
  position: relative;
  font-size: 15px;
  overflow: hidden;
  z-index: 1;

  // .demo-social {
  //   overflow: hidden;
  //   padding-top: 10px;

  //   li {
  //     font-size: 22px;
  //     float: left;

  //     a {
  //       color: $white;
  //       width: 36px;
  //       height: 36px;
  //       line-height: 40px;
  //       background: rgba($color: $white, $alpha: 0.1);
  //       display: block;
  //       text-align: center;
  //       @include rounded-border(50%);
  //       font-size: 16px;

  //       &:hover {
  //         color: $white;
  //         background: $theme-primary-color;
  //       }
  //     }
  //   }

  //   li + li {
  //     margin-left: 15px;

  //     @media (max-width: 1200px) {
  //       margin-left: 15px;
  //     }
  //   }
  // }

.practice-areas{
  display: flex;
  gap: 50px;
}

  ul {
    list-style: none;
  }

  p {
    color: $white;
  }

  li {
    color: $white;
  }

  .container {
    position: relative;
  }

  .wpo-upper-footer {
    padding: 80px 0;
    position: relative;
    overflow: hidden;

    .ft-shape {
      position: absolute;
      right: 0;
      bottom: -10%;
      z-index: -1;

      @media (max-width: 767px) {
        bottom: -30px;
      }
    }
  }

  @media (max-width: 991px) {
    .wpo-upper-footer {
      padding: 50px 0 0;
    }
  }

  @media (max-width: 767px) {
    .wpo-upper-footer {
      padding: 60px 0 0;
    }
  }

  @media (max-width: 991px) {
    .wpo-upper-footer .col {
      min-height: 235px;
      margin-bottom: 70px;
    }
  }

  @media (max-width: 767px) {
    .wpo-upper-footer .col {
      min-height: auto;
      margin-bottom: 60px;
    }
  }

  .widget-title {
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    .widget-title {
      margin-bottom: 20px;
    }
  }

  .widget-title {
    h3 {
      font-weight: 400;
      font-size: 25px;
      line-height: 34px;
      color: $white;
      margin: 0;
      position: relative;
      font-family: $heading-font;
    }
  }

  .about-widget {
    max-width: 350px;

    .logo {
      max-width: 180px;
    }
  }

  .about-widget {
    p {
      margin-bottom: 0.8em;
      line-height: 1.9em;
      color: $white;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      overflow: hidden;
      padding-top: 10px;

      li {
        font-size: 22px;
        float: left;

        a {
          color: $white;
          width: 36px;
          height: 36px;
          line-height: 40px;
          background: rgba($color: $white, $alpha: 0.1);
          display: block;
          text-align: center;
          @include rounded-border(50%);
          font-size: 16px;

          &:hover {
            color: $white;
            background: $theme-primary-color;
          }
        }
      }

      li + li {
        margin-left: 15px;

        @media (max-width: 1200px) {
          margin-left: 15px;
        }
      }
    }
  }

  .wpo-service-link-widget {
    padding-left: 70px;
  }

  .link-widget {
    overflow: hidden;

    &.s2 {
      padding-left: 50px;

      @media (max-width: 1200px) {
        padding: 0;
      }
    }

    ul {
      li {
        position: relative;
        z-index: 1;
        padding: 6px 0;
        padding-left: 20px;

        &:before {
          position: absolute;
          left: 0px;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          z-index: -1;
          width: 6px;
          height: 6px;
          background: #d9d9d9;
          border-radius: 50%;
        }

        a {
          color: $white;
          font-size: 16px;

          &:hover {
            color: $theme-primary-color;
          }
        }
      }
    }
  }

  /*** newsletter-widget ***/
  .newsletter-widget {
    form {
      margin-top: 25px;
      position: relative;

      input {
        background-color: transparent;
        height: 60px;
        color: $white;
        padding: 6px 15px;
        border: 1px solid #989898;
        box-shadow: none;
        border-radius: 0;

        &::-webkit-input-placeholder {
          color: $white;
        }

        &:-ms-input-placeholder {
          color: $white;
        }

        &::placeholder {
          color: $white;
        }
      }

      .submit {
        margin-top: 20px;

        button {
          background: $theme-primary-color;
          border: 0;
          outline: 0;
          font-size: 20px;
          color: $white;
          width: 100%;
          height: 60px;
          line-height: 60px;
        }
      }
    }
  }

  .wpo-lower-footer {
    text-align: left;

    .row {
      padding: 20px 0;
      position: relative;
      // border-top: 1px solid #30465C;
      border-top: 1px solid #cdb091;
    }

    @media (max-width: 991px) {
      text-align: center;
    }

    ul {
      li {
        color: #d9d9d9;
        a {
          color: #d9d9d9;
        }
      }
    }

    .link {
      ul {
        display: flex;
        justify-content: flex-end;

        @media (max-width: 991px) {
          justify-content: center;
          margin-top: 10px;
          flex-wrap: wrap;
        }

        li {
          a {
            color: #d9d9d9;

            &:hover {
              color: $theme-primary-color;
            }
          }

          & + li {
            padding-left: 20px;
            position: relative;

            @media (max-width: 375px) {
              padding-left: 5px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
