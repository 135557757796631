/* 3.2 wpo-about-section */

.wpo-about-section {
  @media (max-width: 991px) {
    padding-top: 40px;
  }
}

.wpo-about-section,
.wpo-about-section-s2,
.wpo-about-section-s3,
.wpo-about-section-s4 {
  background: $section-bg-dark;

  .wpo-about-section-wrapper {
    position: relative;
    z-index: 1;
  }

  .wpo-about-img {
    padding: 20px;
    border: 1px solid #786653;
    margin-right: 60px;

    @media (max-width: 1199px) {
      margin-right: 20px;
    }

    @media (max-width: 991px) {
      margin-right: 0;
    }

    @media (max-width: 575px) {
      padding: 10px;
    }

    img {
      width: 100%;
    }
  }

  .wpo-about-content {
    max-width: 565px;

    @media (max-width: 991px) {
      padding-left: 0;
      margin-top: 50px;
    }

    @media (max-width: 575px) {
      margin-top: 20px;
    }

    h2 {
      font-weight: 400;
      font-size: 50px;
      line-height: 69px;
      color: $white;
      margin-bottom: 30px;

      @media (max-width: 1399px) {
        font-size: 35px;
        line-height: 50px;
      }

      @media (max-width: 1199px) {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px;
      }

      @media (max-width: 767px) {
        font-size: 25px;
        line-height: 30px;
      }
    }

    P {
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: $white;
      margin-bottom: 20px;

      @media (max-width: 575px) {
        font-size: 16px;
      }
    }

    .exprience {
      margin-top: 0px;
      display: flex;

      @media (max-width: 490px) {
        flex-wrap: wrap;
      }

      .exprience-left {
        margin-right: 20px;
        overflow: hidden;
        flex-basis: 50%;

        @media (max-width: 1199px) {
          flex-basis: 35%;
        }

        @media (max-width: 490px) {
          flex-basis: 100%;
        }

        h4 {
          font-weight: 400;
          font-size: 250px;
          line-height: 210px;
          text-transform: uppercase;
          background-image: url(../../images/about/text-bg.png);
          background-repeat: repeat;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-size: cover;

          @media (max-width: 1199px) {
            font-size: 150px;
            line-height: 130px;
          }
        }
      }

      .exprience-right {
        margin-top: 20px;
        overflow: hidden;
        flex-basis: 50%;

        @media (max-width: 1199px) {
          flex-basis: 65%;
          margin-top: 0;
        }

        @media (max-width: 490px) {
          flex-basis: 100%;
        }

        h4 {
          font-weight: 400;
          font-size: 30px;
          line-height: 41px;
          color: $white;
          margin-bottom: 10px;

          @media (max-width: 1199px) {
            font-size: 25px;
            margin-bottom: 0px;
          }
        }

        p {
          margin-bottom: 0;
          color: $white;
          font-weight: 400;
          font-size: 18px;
          line-height: 30px;

          @media (max-width: 1199px) {
            font-size: 15px;
          }
        }
      }

      p {
        font-size: 16px;
        color: #687693;
        margin-bottom: 30px;
      }
    }
  }
}

.visible-text {
  position: absolute;
  top: -50px;
  z-index: -1;
  right: 50px;

  @media (max-width: 991px) {
    display: none;
  }

  h2 {
    font-weight: 400;
    font-size: 250px;
    line-height: 220px;
    text-transform: uppercase;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #33475a;
    opacity: 0.5;

    @media (max-width: 1399px) {
      font-size: 200px;
      line-height: 180px;
    }

    @media (max-width: 1199px) {
      font-size: 150px;
      line-height: 120px;
    }

    @media (max-width: 991px) {
      font-size: 120px;
      line-height: 115px;
    }

    @media (max-width: 767px) {
      font-size: 100px;
      line-height: 95px;
    }

    @media (max-width: 575px) {
      font-size: 70px;
      line-height: 75px;
    }

    @media (max-width: 450px) {
      font-size: 50px;
      line-height: 55px;
    }
  }
}

/* 3.3 wpo-practice-section */

.wpo-practice-section,
.wpo-practice-section-s2 {
  background: $section-bg-dark;
  padding-left: 100px;

  @media (max-width: 1850px) {
    padding-left: 100px;
  }

  @media (max-width: 1750px) {
    padding-left: 40px;
  }

  @media (max-width: 1650px) {
    padding-left: 0px;
  }

  .wpo-practice-wrap {
    position: relative;
    z-index: 1;
    right: -150px;

    @media (max-width: 1650px) {
      right: -80px;
    }

    @media (max-width: 1500px) {
      right: -80px;
    }

    @media (max-width: 1440px) {
      right: -50px;
    }

    @media (max-width: 991px) {
      right: 0px;
    }

    .wpo-practice-items {
      .wpo-practice-item {
        margin-bottom: 30px;
        position: relative;

        .wpo-practice-img {
          position: relative;

          &:before {
            position: absolute;
            left: 0;
            bottom: -10px;
            width: 100%;
            height: 100%;
            content: "";
            background: linear-gradient(
              180deg,
              rgba(30, 46, 62, 0) 0%,
              rgba(30, 46, 62, 0.29) 17.65%,
              rgba(6, 6, 4, 0.78) 46.02%,
              #000000 70.92%
            );
            opacity: 0.8;
            z-index: 1;
            transition: all 0.3s;
          }

          img {
            width: 100%;
          }
        }

        .wpo-practice-content {
          padding: 30px;
          position: absolute;
          bottom: 0;
          z-index: 19;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          transition: all 0.3s;

          @media (max-width: 500px) {
            padding: 30px 15px;
          }

          .b-top {
            overflow: hidden;
            transition: all 0.3s;

            .b-top-inner {
              bottom: -90px;
              position: relative;
              transition: all 0.3s;
            }

            p {
              color: $white;
              height: 1px;
              overflow: hidden;
              margin: 0;
              transition: all 0.3s;
              min-height: 70px;
              max-height: 70px;
              height: 70px;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;

              @media (max-width: 991px) {
                font-size: 16px;
              }
            }
          }

          h2 {
            font-weight: 400;
            font-size: 40px;
            line-height: 55px;
            font-family: $heading-font;
            margin-bottom: 15px;
            margin-top: 0;

            @media (max-width: 767px) {
              font-size: 30px;
              margin-bottom: 10px;
            }

            a {
              color: $white;

              &:hover {
                color: $theme-primary-color;
              }
            }
          }

          p {
            font-weight: 400;
            font-size: 18px;
            line-height: 23px;
          }
        }

        &:hover {
          .wpo-practice-content {
            .b-top {
              .b-top-inner {
                bottom: 0;
              }
            }
          }
        }
      }

      .slick-arrow {
        background: $white;
        width: 50px;
        height: 50px;
        line-height: 68px;
        // background: #24384b;
        background: #ffff;
        color: $theme-primary-color;
        padding: 0;
        font-size: 0px;
        margin: 0;
        border-radius: 0;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        -ms-transition: all 0.3s;
        transition: all 0.3s;
        z-index: 1;

        &:hover {
          &:after {
            opacity: 1;
            visibility: visible;
          }
        }

        @media (max-width: 767px) {
          display: none !important;
        }
      }

      .slick-next {
        position: absolute;
        left: auto;
        right: 300px;
        top: -100px;

        @media (max-width: 1700px) {
          right: 100px;
        }

        &:before {
          font-family: "themify";
          content: "\e628";
          //   color: $theme-primary-color;
          color: #000;
        }
      }

      .slick-prev {
        position: absolute;
        top: -100px;
        left: auto;
        right: 370px;

        @media (max-width: 1700px) {
          right: 170px;
        }

        &:after {
          left: -3px;
          bottom: -3px;
          border-right: 0 !important;
          border-left: 3px solid $theme-primary-color;
          border-bottom: 3px solid $theme-primary-color;
        }

        &:before {
          font-family: "themify";
          content: "\e629";
          //   color: $theme-primary-color;
          color: #000;
        }
      }

      .slick-list {
        margin: 0 -10px;
      }

      .slick-slide {
        padding: 0 10px;
      }
    }
  }
}

.visible-text.s2 {
  right: auto;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1199px) {
    top: -20px;
  }

  @media (max-width: 991px) {
    right: 50px;
    left: auto;
    transform: unset;
    top: -15;
  }
}

/*3.4 wpo-testimonials-section */

.wpo-testimonials-section,
.wpo-testimonials-section-s2 {
  background: $section-bg-dark;
  position: relative;
  padding-top: 90px;

  @media (max-width: 991px) {
    padding-top: 60px;
  }

  @media (max-width: 767px) {
    padding-top: 50px;
  }

  .wpo-testimonials-wrap {
    background-image: url(../../images/testimonial/bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    padding: 170px 0;

    @media (max-width: 1199px) {
      padding: 80px 30px 100px;
    }

    @media (max-width: 575px) {
      padding: 50px 20px 70px;
    }

    .row {
      justify-content: center;
    }

    .wpo-testimonials-active {
      p {
        font-weight: 400;
        font-size: 22px;
        line-height: 40px;
        color: $white;
        max-width: 810px;
        margin: 0 auto;

        @media (max-width: 575px) {
          font-size: 18px;
          margin: 0;
          max-width: 100%;
          line-height: 30px;
        }
      }

      .wpo-testimonial-info {
        margin-top: 30px;

        .wpo-testimonial-info-img {
          position: relative;
          z-index: 1;
          width: 75px;
          height: 75px;
          margin: 0 auto;
          margin-bottom: 20px;

          &:before {
            position: absolute;
            left: -3px;
            top: -3px;
            width: 110%;
            height: 109%;
            content: "";
            background: $theme-primary-color;
            z-index: -1;
            border-radius: 50px;
          }

          img {
            border-radius: 50%;
          }
        }

        .wpo-testimonial-info-text {
          h5 {
            color: $white;
            font-weight: 400;
            font-size: 25px;
            line-height: 34px;
            margin-bottom: 0;

            @media (max-width: 1399px) {
              font-size: 20px;
            }
          }

          span {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: $white;
          }
        }
      }

      .owl-nav {
        display: none;
      }

      .slick-dots {
        bottom: -50px;

        @media (max-width: 1199px) {
          bottom: -30px;
        }

        @media (max-width: 991px) {
          bottom: 20px;
        }

        li button:before {
          font-size: 12px;
          color: $theme-primary-color;
        }
      }
    }
  }
}

/*3.5 wpo-case-section */

.wpo-case-section,
.wpo-case-section-s2,
.wpo-case-section-s3 {
  background: $section-bg-dark;

  .wpo-section-title {
    @include media-query(991px) {
      margin-bottom: 20px;
    }
  }

  .wpo-case-wrap {
    position: relative;
    z-index: 1;
  }

  .case-btn {
    text-align: right;

    @include media-query(991px) {
      text-align: left;
      margin-bottom: 50px;
    }
  }

  .case-grids {
    .grid {
      img {
        width: 100%;
      }
    }
  }

  .grid {
    .img-holder {
      position: relative;

      .hover-content {
        transition: all 0.3s;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        width: 100%;
        display: flex;
        justify-content: center;

        .theme-btn {
          transform: scale(0);
          transition: all 0.3s;

          @media (max-width: 1200px) {
            font-size: 14px;
          }

          @media (max-width: 991px) {
            padding: 15px 20px;
          }

          @media (max-width: 767px) {
            padding: 8px 12px;
          }
        }
      }

      &:before {
        position: absolute;
        left: 2%;
        top: 2%;
        width: 96%;
        height: 96%;
        content: "";
        background: transparentize($section-bg-dark-2, 0.3);
        opacity: 0;
        transition: all 0.3s;
        transform: scale(0);
      }
    }
  }

  .grid:hover .hover-content {
    opacity: 1;

    .theme-btn {
      transform: scale(1);
    }
  }

  .grid:hover {
    .img-holder {
      &:before {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

/* 3.6 wpo-fun-fact-section */

.wpo-fun-fact-section {
  background: $section-bg-dark;
  position: relative;
  background-size: cover;
  z-index: 1;
  padding: 80px 0 90px;

  @media (max-width: 991px) {
    padding: 30px 0 40px;
  }

  h2 {
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 1.8em;

    @include media-query(991px) {
      font-size: 26px;
    }

    @include media-query(767px) {
      font-size: 25px;
    }
  }

  .wpo-fun-fact-grids .grid {
    width: 25%;
    float: left;

    @include media-query(991px) {
      width: 50%;
      margin-bottom: 30px;
    }
  }

  .grid {
    .info {
      display: flex;
      align-items: center;

      @include media-query(991px) {
        display: block;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
      }
    }

    h3 {
      font-size: 80px;
      font-weight: 400;
      line-height: 110px;
      color: $white;
      font-family: $heading-font;
      display: flex;
      align-items: center;
      margin-right: 20px;
      margin-bottom: 0;

      @include media-query(1399px) {
        font-size: 60px;
        margin-right: 10px;
      }

      @include media-query(1199px) {
        font-size: 45px;
      }

      @include media-query(991px) {
        font-size: 40px;
        display: block;
      }

      @include media-query(767px) {
        font-size: 35px;
      }

      span {
        font-family: $heading-font;
      }
    }
  }

  .grid h3 + p {
    font-weight: 400;
    line-height: 35px;
    font-size: 25px;
    margin: 0;
    color: $white;
    max-width: 100px;

    @include media-query(1399px) {
      font-size: 20px;
      line-height: 25px;
    }

    @include media-query(1199px) {
      font-size: 18px;
    }

    @include media-query(991px) {
      font-size: 16px;
      max-width: 100%;
    }
  }
}

/* 3.7 wpo-team-section */
.wpo-team-section {
  padding-top: 200px;
  padding-bottom: 90px;
  background: $section-bg-dark;

  @media (max-width: 767px) {
    padding-bottom: 60px;
    padding-top: 60px;
  }

  .wpo-team-wrap {
    position: relative;
    z-index: 1;
    padding-top: 100px;

    .row {
      margin: 0 -7.5px;

      @media (max-width: 991px) {
        margin: 0 -15px;
      }
    }

    .col {
      padding: 0;

      @media (max-width: 991px) {
        padding: 0 15px;
      }
    }

    .wpo-team-item {
      margin-bottom: 30px;
      position: relative;
      overflow: hidden;

      .wpo-team-img {
        position: relative;

        &:before {
          position: absolute;
          left: 0;
          bottom: -10px;
          width: 100%;
          height: 100%;
          content: "";
          //   background: linear-gradient(
          //     180deg,
          //     rgba(30, 46, 62, 0) 0%,
          //     rgba(30, 46, 62, 0.29) 28.65%,
          //     rgba(24, 40, 55, 0.78) 63.02%,
          //     #101a25 97.92%
          //   );
          background: linear-gradient(
            180deg,
            rgba(30, 46, 62, 0) 7%,
            rgba(30, 46, 62, 0.29) 31.65%,
            rgba(6, 6, 4, 0.78) 66.02%,
            #000000 95.92%
          );
          z-index: 1;
          transition: all 0.3s;
        }

        img {
          width: 100%;
        }
      }

      .wpo-team-text {
        padding: 30px;
        background: none;
        text-align: center;
        position: absolute;
        transition: all 0.3s;
        left: 0;
        bottom: 0;
        z-index: 1;
        width: 100%;
        bottom: -70px;
        transition: all 0.3s;

        &:before {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 3px;
          background: $theme-primary-color;
          content: "";
          transition: all 0.3s;
        }

        h2 {
          font-weight: 400;
          font-size: 30px;
          line-height: 41px;
          color: $white;
          margin-bottom: 0px;

          @media (max-width: 1199px) {
            font-size: 28px;
          }

          a {
            color: $white;

            &:hover {
              color: $theme-primary-color;
            }
          }
        }

        span {
          color: $theme-primary-color;
        }

        .social {
          ul {
            list-style: none;
            display: flex;
            justify-content: center;
            margin-top: 20px;

            li {
              transition: all 0.3s;

              & + li {
                margin-left: 15px;
              }

              a {
                display: block;
                width: 40px;
                height: 40px;
                background: #24384b;
                line-height: 43px;
                text-align: center;
                color: $white;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 50%;
                font-size: 15px;

                &:hover {
                  background: $theme-primary-color;
                }
              }
            }
          }
        }
      }

      &:hover {
        .wpo-team-text {
          bottom: 0;

          &:before {
            width: 100%;
          }
        }
      }
    }

    .slick-arrow {
      background: $white;
      width: 50px;
      height: 50px;
      line-height: 68px;
      //   background: #24384b;
      background: #ffff;
      color: $theme-primary-color;
      padding: 0;
      font-size: 0px;
      margin: 0;
      border-radius: 0;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      -ms-transition: all 0.3s;
      transition: all 0.3s;
      z-index: 1;

      &:hover {
        &:after {
          opacity: 1;
          visibility: visible;
        }
      }

      @media (max-width: 767px) {
        display: none !important;
      }
    }

    .slick-next {
      position: absolute;
      left: auto;
      right: 0px;
      top: -100px;

      @media (max-width: 1700px) {
        right: 10px;
      }

      &:before {
        font-family: "themify";
        content: "\e628";
        // color: $theme-primary-color;
        color: #000;
      }
    }

    .slick-prev {
      position: absolute;
      top: -100px;
      left: auto;
      right: 70px;

      &:after {
        left: -3px;
        bottom: -3px;
        border-right: 0 !important;
        border-left: 3px solid $theme-primary-color;
        border-bottom: 3px solid $theme-primary-color;
      }

      &:before {
        font-family: "themify";
        content: "\e629";
        // color: $theme-primary-color;
        color: #000;
      }
    }

    .slick-list {
      margin: 0 -10px;
    }

    .slick-slide {
      padding: 0 10px;
    }

    .owl-dots {
      bottom: -10px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      @media (min-width: 575px) {
        display: none;
      }

      button {
        border: 0;
        width: 10px;
        height: 12px;
        margin: 5px;
        border-radius: 50%;

        &.active {
          background: $theme-primary-color;
        }
      }
    }
  }

  &.s2 {
    .wpo-section-title-s2 {
      h2 {
        color: $dark-gray;
      }
    }
  }
}

/* 3.8 wpo-contact-section */

.wpo-contact-section,
.wpo-contact-section-s2 {
  background: $section-bg-dark-2;

  .contact-info-wrap {
    .contact-info-item {
      margin-bottom: 55px;
      display: flex;
      align-items: center;

      &:last-child {
        margin-bottom: 0;
      }

      .contact-info-icon {
        margin-right: 20px;

        i {
          font-size: 50px;
          color: $white;
        }
      }

      .contact-info-text {
        h4 {
          font-weight: 400;
          font-size: 22px;
          line-height: 30px;
          color: $white;
          margin-bottom: 10px;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: $white;
          margin-bottom: 0;
          overflow-wrap: anywhere;
        }
      }
    }
  }

  .wpo-contact-map {
    padding: 20px 0;
    border-top: 1px solid #9a9a9a;
    border-bottom: 1px solid #9a9a9a;

    iframe {
      height: 415px;
      width: 100%;
      filter: grayscale(100%);
    }
  }

  .wpo-contact-section-wrapper {
    position: relative;

    .wpo-contact-form-area {
      padding: 60px 40px 80px;
      position: relative;
      z-index: 9;
      border: 1px solid #9a9a9a;
      max-width: 540px;
      margin: 0 auto;

      @media (max-width: 1199px) {
        padding: 40px 20px;
        max-width: 360px;
      }

      @media (max-width: 991px) {
        max-width: 100%;
        margin: 50px 0;
      }

      .form-control {
        width: 100%;
        height: 60px;
        margin-bottom: 20px;
        border: 0;
        border-bottom: 1px solid #9a9a9a;
        background: transparent;
        color: $white;
        border-radius: 0;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        padding: 0;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &::-webkit-input-placeholder {
          color: $white;
        }

        &:-ms-input-placeholder {
          color: $white;
        }

        &::placeholder {
          color: $white;
        }
      }

      textarea {
        &.form-control {
          height: 130px;
        }
      }

      select.form-control {
        color: $white;
        -webkit-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        -moz-appearance: none;
        background: transparent url(../../images/select-icon2.png) no-repeat
          calc(100% - 15px) center;
        position: relative;

        option {
          background: darken($section-bg-dark, 1);
          border: 0;
        }
      }

      .submit-area {
        margin-top: 50px;
        text-align: center;
      }

      .errorMessage {
        color: red;
        margin-top: -10px;
        margin-bottom: 10px;
      }
    }
  }
}

/* 3.9 wpo-blog-section */

.wpo-blog-section {
  padding-bottom: 90px;
  background: $section-bg-dark;

  @media (max-width: 767px) {
    padding-bottom: 60px;
    padding-top: 60px;
  }

  .wpo-blog-wrap {
    position: relative;
    z-index: 1;
  }

  .see-more-btn {
    text-align: right;

    @media (max-width: 991px) {
      text-align: left;
      margin-bottom: 30px;
    }

    a {
      display: inline-block;
      padding: 10px 25px;
      border: 1px solid #7e7e7e;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: $white;

      &:hover {
        background: $theme-primary-color;
        border-color: $theme-primary-color;
      }
    }
  }

  .wpo-blog-items {
    .row {
      margin: 0 -20px;
    }

    .col {
      padding: 0 20px;
    }
  }

  .wpo-blog-item {
    margin-bottom: 30px;

    .wpo-blog-img {
      img {
        width: 100%;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
    }

    .wpo-blog-content {
      padding-top: 30px;

      ul {
        list-style: none;
        display: flex;
        margin-bottom: 20px;

        @media (max-width: 991px) {
          margin-bottom: 10px;
        }

        li {
          color: #bebebe;
          font-weight: 400;
          font-size: 18px;
          line-height: 23px;

          @media (max-width: 1399px) {
            font-size: 15px;
          }

          @media (max-width: 991px) {
            font-size: 14px;
          }

          & + li {
            padding-left: 20px;
            margin-left: 20px;
            position: relative;

            @media (max-width: 575px) {
              padding-left: 10px;
              margin-left: 10px;
            }

            &:before {
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 6px;
              height: 6px;
              content: "";
              background: #bebebe;
              border-radius: 50%;
            }
          }

          a {
            color: #bebebe;

            &:hover {
              color: $theme-primary-color;
            }
          }
        }
      }

      h2 {
        font-family: $heading-font;
        margin-bottom: 25px;
        font-weight: 400;
        font-size: 26px;
        line-height: 36px;
        margin-top: 0;

        @media (max-width: 1399px) {
          font-size: 24px;
        }

        @media (max-width: 1200px) {
          font-size: 22px;
        }

        @media (max-width: 991px) {
          margin-bottom: 10px;
        }

        a {
          color: $white;

          &:hover {
            color: $theme-primary-color;
          }
        }
      }

      .b-btn {
        display: inline-block;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        padding-left: 60px;
        color: $theme-primary-color;
        position: relative;

        @media (max-width: 575px) {
          font-size: 16px;
          padding-left: 40px;
        }

        &:before {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 50px;
          height: 2px;
          background: $theme-primary-color;
          content: "";
          transition: all 0.3s;

          @media (max-width: 991px) {
            width: 30px;
          }
        }

        &:hover {
          &:before {
            left: -5px;
          }
        }
      }
    }
  }
}
